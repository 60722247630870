import { Button, Calendar, FileUpload } from "@/uiCore";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToast } from "@/redux/features";
import { listToast } from "@/constants";
import { removeSpecialCharacter } from "@/utils";
import { Dialogz } from "./Dialogz";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdownz } from "./FormList";
import { useListDepartment, useListProjectForm } from "@/modules/project/utils";
import { DropdownForm } from "./FormUpdate";

export const Import = (props) => {
  const {
    title,
    action,
    visible,
    setVisible,
    MoreOption,
    handleData,
    handleSuccess = () => {},
    type,
  } = props;
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectFile, setSelectFile] = useState("");
  const [date, setDate] = useState();

  const handleImport = (e) => {
    const file = e.target.files[0];
    if (file) {
      const isExcelFile = /\.(xlsx|xls)$/i.test(file.name);
      if (!isExcelFile) {
        return dispatch(
          setToast({ ...listToast[1], detail: "Vui lòng chỉ chọn file excel!" })
        );
      }
      setFile({ ...file, file: file });
      setSelectFile(file && file.name);
    }
  };

  async function fetchDataSubmit(info) {
    const response = await action(info);
    if (response) setLoading(false);
    if (response?.status) {
      handleSuccess();
      dispatch(
        setToast({ ...listToast[0], detail: `Import ${title} thành công!` })
      );
    } else dispatch(setToast({ ...listToast[1], detail: "Có lỗi xảy ra!" }));
  }
  const convert = (date) => {
    return date.toString().padStart(2, "0");
  };
  const handleSubmit = () => {
    let infos = handleData ? handleData() : {};

    if (type == "phanbo") {
      if (file && file.file && date) {
        let info = {
          file: file.file,
          month: convert(date?.getMonth() + 1),
          year: date?.getFullYear(),
        };
        if (infos) {
          if (typeof infos === "string") {
            dispatch(setToast({ ...listToast[1], detail: infos }));
            return;
          } else if (typeof infos === "object") {
            info = { ...info, ...infos };
          }
        }
        setLoading(true);
        fetchDataSubmit(info);
      } else {
        if (!date) {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn tháng năm" })
          );
        } else {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn file!" })
          );
        }
      }
    } else {
      if (file && file.file && date?.pb_id && date?.category_id) {
        let info = {
          file: file.file,
          pb_id: date?.pb_id,
          category_id: date?.category_id,
        };
        if (infos) {
          if (typeof infos === "string") {
            dispatch(setToast({ ...listToast[1], detail: infos }));
            return;
          } else if (typeof infos === "object") {
            info = { ...info, ...infos };
          }
        }
        setLoading(true);
        fetchDataSubmit(info);
      } else {
        if (!date?.category_id) {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn dự án" })
          );
        } else if (!date?.pb_id) {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn phòng ban" })
          );
        } else {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn file!" })
          );
        }
      }
    }
  };
  const list_department = useListDepartment({
    status: undefined,
  });
  const list_projectForm = useListProjectForm();
  return (
    visible && (
      <Dialogz
        title={"Import " + title}
        visible={visible}
        onHide={() => setVisible(false)}
        width="1200px"
      >
        <div className="card">
          <div className=" mt-3 grid">
            <div className="col-6 pl-3">
              <span className="font-medium text-md mb-2">Thao tác</span>
              <div className="flex flex-row mt-3">
                <label className="p-button p-fileupload-choose p-component">
                  <span className="p-button-text p-clickable">Chọn file</span>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImport}
                    className="p-inputtext p-component"
                  />
                </label>
                <div className=" flex flex-row-reverse">
                  <Button
                    loading={loading}
                    onClick={handleSubmit}
                    label="Import"
                    className="ml-2 "
                    severity="info"
                    size="small"
                    raised
                  />
                  <Button
                    label="Bỏ chọn file"
                    onClick={() => {
                      setSelectFile("");
                      setFile(null);
                    }}
                    className="ml-2 "
                    severity="danger"
                    size="small"
                  />
                  {/* 
                  <Button
                    label="Tải file mẫu"
                    className="ml-2 mt-2"
                    severity="info"
                    size="small"
                    raised
                  /> */}
                </div>
              </div>
              {selectFile && (
                <div className={"mt-2"}>Select file: {selectFile}</div>
              )}
            </div>

            {type == "phanbo" && (
              <div className="col-6 flex flex-column">
                <span className="font-medium text-md mb-3">Chọn tháng</span>
                <FloatLabel>
                  <Calendar
                    inputId="birth_date"
                    value={date ? date : ""}
                    onChange={(e) => setDate(new Date(e.value))}
                    view="month"
                    dateFormat="mm/yy"
                  />
                  <label htmlFor="birth_date">Tháng năm</label>
                </FloatLabel>
              </div>
            )}
            {type === "khachhang" && (
              <>
                <div className="col-6"></div>
                <div className="col-6">
                  <DropdownForm
                    value={date?.category_id}
                    onChange={(e) =>
                      setDate({
                        ...date,
                        category_id: e.target.value ? e.target.value : "",
                      })
                    }
                    options={list_projectForm}
                    optionLabel={(option) =>
                      `${option.cb_title}  (${option.cb_code})`
                    }
                    optionValue="cb_id"
                    label="Dự án (*)"
                  />
                </div>
                <div className="col-6">
                  <DropdownForm
                    value={date?.pb_id}
                    onChange={(e) =>
                      setDate({
                        ...date,
                        pb_id: e.target.value ? e.target.value : "",
                      })
                    }
                    options={list_department}
                    optionLabel={(option) =>
                      `${option.gb_title}  (${option.gb_id})`
                    }
                    optionValue="gb_id"
                    label="Phòng ban (*)"
                  />
                </div>
              </>
            )}
            <div style={{ height: "40vh" }}></div>
            {MoreOption && <MoreOption />}
          </div>
        </div>
      </Dialogz>
    )
  );
};
export const Import2 = (props) => {
  const {
    title,
    action,
    visible,
    setVisible,
    MoreOption,
    handleData,
    handleSuccess = () => {},
    type,
  } = props;
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectFile, setSelectFile] = useState("");
  const [date, setDate] = useState();

  const handleImport = (e) => {
    const file = e.target.files[0];
    if (file) {
      const isExcelFile = /\.(xlsx|xls)$/i.test(file.name);
      if (!isExcelFile) {
        return dispatch(
          setToast({ ...listToast[1], detail: "Vui lòng chỉ chọn file excel!" })
        );
      }
      setFile({ ...file, file: file });
      setSelectFile(file && file.name);
    }
  };

  async function fetchDataSubmit(info) {
    const response = await action(info);
    if (response) setLoading(false);
    if (response?.status) {
      handleSuccess();
      dispatch(
        setToast({ ...listToast[0], detail: `Import ${title} thành công!` })
      );
    } else dispatch(setToast({ ...listToast[1], detail: "Có lỗi xảy ra!" }));
  }
  const convert = (date) => {
    return date.toString().padStart(2, "0");
  };
  const handleSubmit = () => {
    let infos = handleData ? handleData() : {};

    if (type == "phanbo") {
      if (file && file.file && date) {
        let info = {
          file: file.file,
          month: convert(date?.getMonth() + 1),
          year: date?.getFullYear(),
        };
        if (infos) {
          if (typeof infos === "string") {
            dispatch(setToast({ ...listToast[1], detail: infos }));
            return;
          } else if (typeof infos === "object") {
            info = { ...info, ...infos };
          }
        }
        setLoading(true);
        fetchDataSubmit(info);
      } else {
        if (!date) {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn tháng năm" })
          );
        } else {
          dispatch(
            setToast({ ...listToast[1], detail: "Vui Lòng chọn file!" })
          );
        }
      }
    } else {
      if (file && file.file) {
        let info = {
          file: file.file,
          pb_id: date?.pb_id,
          category_id: date?.category_id,
        };
        if (infos) {
          if (typeof infos === "string") {
            dispatch(setToast({ ...listToast[1], detail: infos }));
            return;
          } else if (typeof infos === "object") {
            info = { ...info, ...infos };
          }
        }
        setLoading(true);
        fetchDataSubmit(info);
      } else {
        // if (!date?.category_id) {
        //   dispatch(
        //     setToast({ ...listToast[1], detail: "Vui Lòng chọn dự án" })
        //   );
        // } else if (!date?.pb_id) {
        //   dispatch(
        //     setToast({ ...listToast[1], detail: "Vui Lòng chọn phòng ban" })
        //   );
        // } else {
        //   dispatch(
        //     setToast({ ...listToast[1], detail: "Vui Lòng chọn file!" })
        //   );
        // }
      }
    }
  };
  const list_department = useListDepartment({
    status: undefined,
  });
  const list_projectForm = useListProjectForm();
  return (
    visible && (
      <Dialogz
        title={"Import " + title}
        visible={visible}
        onHide={() => setVisible(false)}
        width="1200px"
      >
        <div className="card">
          <div className=" mt-3 grid">
            <div className="col-6 pl-3">
              <span className="font-medium text-md mb-2">Thao tác</span>
              <div className="flex flex-row mt-3">
                <label className="p-button p-fileupload-choose p-component">
                  <span className="p-button-text p-clickable">Chọn file</span>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImport}
                    className="p-inputtext p-component"
                  />
                </label>
                <div className=" flex flex-row-reverse">
                  <Button
                    loading={loading}
                    onClick={handleSubmit}
                    label="Import"
                    className="ml-2 "
                    severity="info"
                    size="small"
                    raised
                  />
                  <Button
                    label="Bỏ chọn file"
                    onClick={() => {
                      setSelectFile("");
                      setFile(null);
                    }}
                    className="ml-2 "
                    severity="danger"
                    size="small"
                  />
                  {/* 
                  <Button
                    label="Tải file mẫu"
                    className="ml-2 mt-2"
                    severity="info"
                    size="small"
                    raised
                  /> */}
                </div>
              </div>
              {selectFile && (
                <div className={"mt-2"}>Select file: {selectFile}</div>
              )}
            </div>

            <div className="col-6 flex flex-column">
              <span className="font-medium text-md mb-3">Chọn tháng</span>
              <FloatLabel>
                <Calendar
                  inputId="birth_date"
                  value={date ? date : ""}
                  onChange={(e) => setDate(new Date(e.value))}
                  view="month"
                  dateFormat="mm/yy"
                />
                <label htmlFor="birth_date">Tháng năm</label>
              </FloatLabel>
            </div>
            <div style={{ height: "40vh" }}></div>
            {MoreOption && <MoreOption />}
          </div>
        </div>
      </Dialogz>
    )
  );
};
