import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Calendarz,
  GridForm,
  HeaderListForm,
} from "@/components/data_table/FormList";
import { useEffect, useState } from "react";
import {
  useGetListTeambyProject,
  useListAssignCategory,
  useListAssignDate,
  useListDepartment,
} from "@/modules/project/utils";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import { assignDateSingle } from "@/modules/project/api";
import { ToggleButton } from "@/uiCore";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weeks = [
  "Chủ nhật",
  "Thứ 2",
  "Thứ 3",
  "Thứ 4",
  "Thứ 5",
  "Thứ 6",
  "Thứ 7",
];
const Cell = ({ value, data, handleSelectDate = () => {} }) => {
  return (
    <div
      onClick={() => handleSelectDate(value, data)}
      className="text-right border-1 hover:bg-gray-100 cursor-pointer overflow-hidden "
      style={{
        width: "14%",
        height: "8rem",
        borderColor: "#d6d7d8",
        position: "relative",
      }}
    >
      <div className="mt-1 mr-2">{value}</div>
      {value && data !== 0 && data && (
        <div className="flex flex-column gap-1 mt-2 px-2 pt-3">
          <span
            className=" p-1 text-white text-center font-semibold"
            style={{
              backgroundColor: data?.name == "pb" ? "#4db6ac" : "#3388FF",
              fontSize: "0.85rem",
              borderRadius: "10px",
              minHeight: "40px",
              height: "auto",
              width: "90%",
              bottom: "10%",
              left: "5%",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {data?.data}
          </span>
        </div>
      )}
    </div>
  );
};

export const HeaderCalendar = () => {
  return (
    <div className="w-full flex justify-content-center">
      {weeks?.map((w, index) => {
        return (
          <div
            key={index}
            className="text-center align-items-center flex justify-content-center text-white border-1 font-medium"
            style={{
              width: "14%",
              height: "3rem",
              borderColor: "#e9ecef",
              backgroundColor: "#14b8a6",
            }}
          >
            {w}
          </div>
        );
      })}
    </div>
  );
};
const Header = ({ setParams, params }) => {
  const [filter, setFilter] = useState({ date: new Date() });

  const onClear = () => {
    const date = new Date();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    setFilter({ date });
    setParams({ month: "" + month + date.getFullYear() });
  };

  const handleFilter = (filters) => {
    let date = new Date();
    if (filters.date) {
      date = new Date(filters.date);
    }
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    filters.month = "" + month + date.getFullYear();
    return filters;
  };
  const convertFilterDate = (filters) => {
    let date = new Date(filters);
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return "" + month + date.getFullYear();
  };
  const reconvert = (date) => {
    return `${date?.slice(2, 6)}/${date?.slice(0, 2)}/01`;
  };
  return (
    <GridForm
      setParams={setParams}
      filter={filter}
      setFilter={setFilter}
      onClear={onClear}
      handleFilter={handleFilter}
      className="lg:col-9"
      hideButton
    >
      <Calendarz
        showButtonBar={false}
        selectionMode="single"
        view="month"
        dateFormat="mm/yy"
        value={new Date(reconvert(params.month))}
        onChange={(e) =>
          setParams({ ...params, month: convertFilterDate(e.value) })
        }
      />
    </GridForm>
  );
};
const UpdatePage = () => {
  const location = useLocation().pathname;
  const category_id = location.split("/")?.[3];
  const navigate = useNavigate();
  const [days, setDays] = useState([]);
  const [checked, setChecked] = useState(false);
  const list_assign = useListAssignCategory();
  const list_department = useListDepartment({
    status: undefined,
  });

  const [params, setParams] = useState(() => {
    const date = new Date();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return { month: "" + month + date.getFullYear() };
  });
  const convertDate = (date, month) => {
    if (Number(date) < 10) {
      return `0${date}/${month?.slice(0, 2)}/${month?.slice(2, 6)}`;
    }
    return `${date}/${month?.slice(0, 2)}/${month?.slice(2, 6)}`;
  };
  const [dates, setDates] = useState(params);
  // useEffect(() => {
  //   if (list_assign && list_assign?.[0]) {
  //     setDates({
  //       ...dates,
  //       category_id: category_id,
  //     });
  //   }
  // }, [list_assign]);
  const data = useListAssignDate({
    category_id: category_id,
    ...params,
  });
  const list_team = useGetListTeambyProject({ project_id: category_id });
  const uniqueTeamList = list_team.reduce((acc, item) => {
    if (!acc.some(({ name }) => name === item.name)) {
      acc.push({ id: item.team_id, name: item.name });
    }
    return acc;
  }, []);

  useEffect(() => {
    const year = Number(params.month?.slice(2, 6));
    const month = Number(params.month?.slice(0, 2));
    const firstDate = new Date(year, month - 1, 1);
    const lastDate = new Date(year, month, 0);
    const firstDay = firstDate.getDay();
    const lastDay = lastDate.getDay();
    const listDate = data?.filter(
      (item) => item.month?.slice(0, 2) == params.month?.slice(0, 2)
    );
    const newDays = [];
    for (
      let i = 0;
      i < firstDay + lastDate.getDate() + (7 - lastDay) - 1;
      i++
    ) {
      if (i < firstDay) newDays.push({});
      else if (i < firstDay + lastDate.getDate()) {
        const matchingItem = listDate.find(
          (item) => item.date == i - firstDay + 1
        );
        if (matchingItem) {
          newDays.push({
            value: i - firstDay + 1,
            data: matchingItem?.pb_id
              ? {
                  name: "pb",
                  data: list_department?.find(
                    (e) => e?.gb_id == matchingItem.pb_id
                  )?.gb_title,
                  id: matchingItem.pb_id,
                }
              : {
                  name: "team",
                  data: list_team?.find(
                    (e) => e.team_id == matchingItem.team_id
                  )?.name,
                  id: matchingItem.team_id,
                },
          });
        } else {
          newDays.push({ value: i - firstDay + 1 });
        }
      } else newDays.push({});
    }
    setDays(newDays);
  }, [params.month, JSON.stringify(data), JSON.stringify(list_team)]);

  const [showDetail, setShowDetail] = useState(false);
  const handleHide = () => {
    setDates({
      category_id: category_id,
    });
    setShowDetail(false);
    setChecked(false);
  };
  const handleSelectDate = (date, data) => {
    if (date) {
      setShowDetail(true);
      setDates({
        ...dates,
        single_date: convertDate(date, params.month),
        date: date,
        month: `${params.month.slice(0, 2)}-${params.month.slice(2, 6)}`,
        pb_id: data?.name == "pb" ? data?.id : "",
        team_id: data?.name == "team" ? data?.id : "",
        category_id: category_id,
      });
    }
  };

  const handleCheck = (e) => {
    setChecked(e);
    setDates({ ...dates, pb_id: "", team_id: "" });
  };
  const handleDataDate = () => {
    return dates;
  };
  const handleReturn = () => {
    navigate("/page_list");
  };
  const isDepartmentSelected = dates?.team_id
    ? false
    : dates?.pb_id
    ? true
    : checked;
  return (
    <div className="w-full">
      {showDetail && (
        <FormUpdateDialog
          width="1000px"
          visible={showDetail}
          setVisible={setShowDetail}
          title="Chi tiết lịch phân bổ theo ngày"
          refreshObjects={[setDates]}
          handleData={handleDataDate}
          actions={{ update: assignDateSingle }}
          checkId={Number(showDetail)}
          setParams={setParams}
          onHide={handleHide}
          noHuy={true}
        >
          <div className="w-full flex flex-row-reverse">
            <ToggleButton
              checked={dates?.team_id ? false : dates?.pb_id ? true : checked}
              onLabel="Phân bổ Phòng ban"
              offLabel="Phân bổ Nhóm nhân viên"
              onChange={(e) => handleCheck(e.value)}
              className="mr-2"
            />
            {/* <Button
              label={
                dates?.team_id
                  ? "Phân bổ Nhóm nhân viên"
                  : "Phân bổ theo Phòng ban"
              }
              style={{ backgroundColor: dates?.team_id ? "" : "black" }}
              onClick={()=>handleCheck()}
            /> */}
          </div>

          <InputForm label="Ngày" value={dates?.single_date} readOnly />
          {isDepartmentSelected ? (
            <DropdownForm
              value={dates?.pb_id}
              onChange={(e) =>
                setDates({
                  ...dates,
                  pb_id: e.target.value ? e.target.value : "",
                })
              }
              options={list_department}
              optionLabel={(option) =>
                `${option.gb_title}  (${option.gb_id} - ${option.reference_code}) `
              }
              optionValue="gb_id"
              label="Phòng ban"
            />
          ) : (
            <DropdownForm
              value={dates?.team_id}
              onChange={(e) =>
                setDates({
                  ...dates,
                  team_id: e.target.value ? e.target.value : "",
                })
              }
              options={uniqueTeamList}
              optionLabel={(option) => `${option.name} `}
              optionValue="id"
              label="Nhóm nhân sự"
            />
          )}

          <div style={{ height: "25vh" }}></div>
        </FormUpdateDialog>
      )}

      <div className="card">
        <HeaderListForm title="Chi tiết lịch phân bổ" />
        <div className="px-3">
          <Header setParams={setParams} params={params} />
        </div>
        <div className="flex flex-column align-items-center justify-content-center">
          <HeaderCalendar />
          <div className="flex flex-wrap w-full justify-content-center">
            {days.map((day, index) => (
              <Cell
                key={index}
                value={day.value}
                data={day.data}
                handleSelectDate={handleSelectDate}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row-reverse m-3">
          <Button severity="danger" onClick={() => handleReturn()}>
            Quay về
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UpdatePage;
