import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Calendarz,
  GridForm,
  HeaderListForm,
} from "@/components/data_table/FormList";
import { useEffect, useState } from "react";
import {
  useGetListPbTour,
  useGetListTeambyProject,
  useListAssignDate,
  useListDepartment,
} from "@/modules/project/utils";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
  MultiSelectForm,
} from "@/components/data_table/FormUpdate";
import { assignDateSingle, assignDateSingleNew } from "@/modules/project/api";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weeks = [
  "Chủ nhật",
  "Thứ 2",
  "Thứ 3",
  "Thứ 4",
  "Thứ 5",
  "Thứ 6",
  "Thứ 7",
];
const exchange = (a) => {
  const b = a
    ?.substring(1, a.length - 1)
    ?.split(",")
    ?.map(Number);
  return b;
};
const Cell = ({ value, data, handleSelectDate = () => {} }) => {
  const list_department = useListDepartment({
    status: undefined,
  });
  const listpb = useGetListPbTour();
  return (
    <div
      onClick={() => handleSelectDate(value, data)}
      className="border-1 hover:bg-gray-100 cursor-pointer overflow-hidden flex-column"
      style={{
        width: "14%",
        height: "18rem",
        borderColor: "#d6d7d8",
        // position: "relative",
      }}
    >
      <div className="text-right w-full pt-2 pr-3 pb-2 text-xl font-medium">
        {value}
      </div>
      <div className="flex flex-column gap-1 pl-3 pt-2 w-full">
        <div className="grid">
          {data?.map((i) => (
            <span
              className=" p-1 text-white text-center font-semibold m-2"
              style={{
                backgroundColor: "#4db6ac",
                fontSize: "0.85rem",
                borderRadius: "10px",
                minHeight: "40px",
                height: "auto",
                width: "40%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {listpb?.find((e) => e?.gb_id == i)?.code}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export const HeaderCalendar = () => {
  return (
    <div className="w-full flex justify-content-center">
      {weeks?.map((w, index) => {
        return (
          <div
            key={index}
            className="text-center align-items-center flex justify-content-center text-white border-1 font-medium"
            style={{
              width: "14%",
              height: "3rem",
              borderColor: "#e9ecef",
              backgroundColor: "#14b8a6",
            }}
          >
            {w}
          </div>
        );
      })}
    </div>
  );
};
const Header = ({ setParams, params }) => {
  const [filter, setFilter] = useState({ date: new Date() });

  const onClear = () => {
    const date = new Date();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    setFilter({ date });
    setParams({ month: "" + month + date.getFullYear() });
  };

  const handleFilter = (filters) => {
    let date = new Date();
    if (filters.date) {
      date = new Date(filters.date);
    }
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    filters.month = "" + month + date.getFullYear();
    return filters;
  };
  const convertFilterDate = (filters) => {
    let date = new Date(filters);
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return "" + month + date.getFullYear();
  };
  const reconvert = (date) => {
    return `${date?.slice(2, 6)}/${date?.slice(0, 2)}/01`;
  };
  return (
    <GridForm
      setParams={setParams}
      filter={filter}
      setFilter={setFilter}
      onClear={onClear}
      handleFilter={handleFilter}
      className="lg:col-9"
      hideButton
    >
      <Calendarz
        showButtonBar={false}
        selectionMode="single"
        view="month"
        dateFormat="mm/yy"
        value={new Date(reconvert(params.month))}
        onChange={(e) =>
          setParams({ ...params, month: convertFilterDate(e.value) })
        }
      />
    </GridForm>
  );
};
const UpdatePageNew = () => {
  const location = useLocation().pathname;
  const category_id = location.split("/")?.[3];
  const navigate = useNavigate();
  const [days, setDays] = useState([]);
  const listpb = useGetListPbTour();
  //   const list_assign = useListAssignCategory();
  const list_department = useListDepartment({
    status: undefined,
  });
  const [params, setParams] = useState(() => {
    const date = new Date();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return { month: "" + month + date.getFullYear() };
  });
  const convertDate = (date, month) => {
    if (Number(date) < 10) {
      return `0${date}/${month?.slice(0, 2)}/${month?.slice(2, 6)}`;
    }
    return `${date}/${month?.slice(0, 2)}/${month?.slice(2, 6)}`;
  };
  const [dates, setDates] = useState(params);
  // useEffect(() => {
  //   if (list_assign && list_assign?.[0]) {
  //     setDates({
  //       ...dates,
  //       category_id: category_id,
  //     });
  //   }
  // }, [list_assign]);
  const data = useListAssignDate({
    category_id: category_id,
    ...params,
  });

  useEffect(() => {
    const year = Number(params.month?.slice(2, 6));
    const month = Number(params.month?.slice(0, 2));
    const firstDate = new Date(year, month - 1, 1);
    const lastDate = new Date(year, month, 0);
    const firstDay = firstDate.getDay();
    const lastDay = lastDate.getDay();
    const listDate = data?.filter(
      (item) => item.month?.slice(0, 2) == params.month?.slice(0, 2)
    );
    const newDays = [];
    for (
      let i = 0;
      i < firstDay + lastDate.getDate() + (7 - lastDay) - 1;
      i++
    ) {
      if (i < firstDay) newDays.push({});
      else if (i < firstDay + lastDate.getDate()) {
        const matchingItem = listDate.find(
          (item) => item.date == i - firstDay + 1
        );
        if (matchingItem) {
          newDays.push({
            value: i - firstDay + 1,
            data: matchingItem.list_pb ? exchange(matchingItem.list_pb) : [],
          });
        } else {
          newDays.push({ value: i - firstDay + 1 });
        }
      } else newDays.push({});
    }
    setDays(newDays);
  }, [params.month, JSON.stringify(data)]);

  const [showDetail, setShowDetail] = useState(false);
  const handleHide = () => {
    setDates({
      category_id: category_id,
    });
    setShowDetail(false);
  };
  const handleSelectDate = (date, data) => {
    if (date) {
      setShowDetail(true);
      setDates({
        ...dates,
        single_date: convertDate(date, params.month),
        date: date,
        month: `${params.month.slice(0, 2)}-${params.month.slice(2, 6)}`,
        list_pb: data,
        category_id: category_id,
      });
    }
  };
  const handleDataDate = () => {
    return dates;
  };
  const handleReturn = () => {
    navigate("/page_list");
  };

  return (
    <div className="w-full">
      {showDetail && (
        <FormUpdateDialog
          width="1000px"
          visible={showDetail}
          setVisible={setShowDetail}
          title="Chi tiết lịch phân bổ theo ngày"
          refreshObjects={[setDates]}
          handleData={handleDataDate}
          actions={{ update: assignDateSingleNew }}
          checkId={Number(showDetail)}
          setParams={setParams}
          onHide={handleHide}
        >
          <InputForm label="Ngày" value={dates?.single_date} readOnly />
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <MultiSelectForm
                value={
                  typeof dates?.list_pb == "string"
                    ? exchange(dates?.list_pb)
                    : dates?.list_pb
                }
                onChange={(e) =>
                  setDates({ ...dates, list_pb: e.target.value })
                }
                label="Sàn phân bổ (*)"
                options={listpb}
                optionValue="gb_id"
                optionLabel="gb_title"
              />
            </div>
          </div>
          <div style={{ height: "40vh" }}></div>
        </FormUpdateDialog>
      )}

      <div className="card">
        <HeaderListForm title="Chi tiết lịch phân bổ" />
        <div className="px-3">
          <Header setParams={setParams} params={params} />
        </div>
        <div className="flex flex-column align-items-center justify-content-center">
          <HeaderCalendar />
          <div className="flex flex-wrap w-full justify-content-center">
            {days?.map((day, index) => (
              <Cell
                key={index}
                value={day.value}
                data={day.data}
                handleSelectDate={handleSelectDate}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row-reverse m-3">
          <Button severity="danger" onClick={() => handleReturn()}>
            Quay về
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UpdatePageNew;
