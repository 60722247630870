import { useEffect, useState } from "react";
import {
  detailProject,
  getListDepartment,
  getListLead,
  getListAllProject,
  getListAllPage,
  getListAllForm,
  getListProjectForm,
  getListFormPage,
  getListAssignDate,
  listCampaign,
  getCheckId,
  getPbIdByDate,
  getListPage,
  getListFormbyPage,
  getListAssignCategory,
  getListAssignPage,
  getCategoryDetail,
  getListLeadbYCategory,
  listPrioritize,
  listSource,
  getListSheet,
  getListDetailSheet,
  getListAssignSheet,
  listPbTour,
  getListAssignCategorySearch,
  listAssignCategorySearch,
  getUserLogToken,
  getListTeambyProject,
} from "../api";

export const useListLead = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLead({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListLead = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLead({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListDepartment = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDepartment({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useDetailProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await detailProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAllProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAllPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useCountListAllPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAllForm = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllForm({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListProjectForm = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListProjectForm({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListFormPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormPage({ status: 1, ...params });
    if (response?.status) setData(response?.data?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignDate = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignDate({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListCampaign = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listCampaign({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetCheckId = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getCheckId({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetPbIdByDate = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getPbIdByDate({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useListPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListFormbyPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormbyPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListFormbyPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormbyPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useListAssignCategory = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignCategory({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignCategorySearch = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listAssignCategorySearch({
      status: 1,
      ...params,
    });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListAssignCategory = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignCategory({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignPage({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCategoryDetail = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getCategoryDetail({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListLeadbYCategory = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLeadbYCategory({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListPrioritize = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listPrioritize({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListSource = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listSource({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useCountListSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListDetailSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDetailSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useCountListDetailSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDetailSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListPbTour = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listPbTour({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetUserLogToken = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getUserLogToken({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListTeambyProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListTeambyProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
