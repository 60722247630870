import { Import, Import2 } from "@/components/data_table/Import";
export const ImportService2 = ({
  visible,
  setVisible,
  setParams,
  action,
  title,
  type,
}) => {
  const handleSuccess = async () => {
    setParams((pre) => ({ ...pre, render: !pre.render }));
    setVisible(false);
  };
  return (
    <Import2
      title={title}
      visible={visible}
      setVisible={setVisible}
      action={action}
      handleSuccess={handleSuccess}
      type={type}
    />
  );
};
const ImportService = ({
  visible,
  setVisible,
  setParams,
  action,
  title,
  type,
}) => {
  const handleSuccess = async () => {
    setParams((pre) => ({ ...pre, render: !pre.render }));
    setVisible(false);
  };
  return (
    <Import
      title={title}
      visible={visible}
      setVisible={setVisible}
      action={action}
      handleSuccess={handleSuccess}
      type={type}
    />
  );
};
export default ImportService;
