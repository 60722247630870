import { useState } from "react";
import {
  useCountListPage,
  useListAllProject,
  useListPage,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez, StatusBody } from "@/components/data_table";
import {
  Calendarz,
  Dropdownz,
  GridForm,
  HeaderListForm,
  Inputz,
} from "@/components/data_table/FormList";
import { LoadDialog } from "@/components/data_table/Dialogz";
import { Button } from "@/uiCore";
import { Link, useLocation } from "react-router-dom";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import {
  autoZalo,
  refreshLead,
  updateActivePage,
  updateDxcHandle,
  updatePageList,
  updateProjectCampaign,
} from "../api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDispatch } from "react-redux";
import { listToast, status } from "@/constants";
import { setToast } from "@/redux/features";
import { clientId } from "@/axios/clientApi";
const Header = ({ setParams, setFilter, filter, params }) => {
  const list_projectForm = useListProjectForm();

  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Inputz
        value={filter?.key_search}
        className="col-5"
        placeholder="Tìm kiếm theo tên page"
        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
      ></Inputz>
      <Dropdownz
        value={filter?.category_id}
        onChange={(e) => setFilter({ ...filter, category_id: e.target.value })}
        options={list_projectForm}
        className=" col-6"
        placeholder="Chọn dự án"
        optionLabel={(option) => `${option.cb_title}  (${option.cb_code})`}
        optionValue="cb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Dropdownz
        value={filter?.status}
        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        options={[
          { id: 1, name: "ACTIVE" },
          { id: 0, name: "INACTIVE" },
        ]}
        className=" col-3"
        placeholder="Chọn trạng thái"
        style={{ lineHeight: "30px" }}
      ></Dropdownz>

      <div className="col-9"></div>
    </GridForm>
  );
};
const PageList = () => {
  const initParam = useGetParams();
  const [filter, setFilter] = useState();
  const [params, setParams] = useState(initParam);
  const [showConfirm, setShowConfirm] = useState(false);
  const [pageChosen, setPageChosen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const list_project = useListAllProject();
  const list_page = useListPage({
    status: undefined,
    ...params,
    first: undefined,
  });
  const getProject = (id) => {
    return list_project?.filter((e) => e.cb_id == id)?.[0];
  };
  const totalRecords =
    useCountListPage({
      status: undefined,
      ...params,
      first: undefined,
    }) || 0;
  const [showDialog, setShowDialog] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const handleShow = (page, id, category_id) => {
    setShowDialog(true);
    setCampaignName(page);
    setInfos({ ...infos, category_id: category_id, id: id });
  };
  const list_projectForm = useListProjectForm();
  const handleData = () => {
    if (!infos?.category_id) return "Bạn chưa chọn dự án";
    return infos;
  };
  const updateActive = async (id) => {
    await updateActivePage({ id: id });
  };
  const setOnHide = () => {
    setShowConfirm(false);
    setPageChosen({});
  };
  // const handleZalo = async () => {
  //   await autoZalo();
  // };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleUpdate = async () => {
    setLoading(true);
    await updatePageList();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    dispatch(
      setToast({
        ...listToast[0],
        detail: `Cập nhật dữ liệu Page - Chiến dịch thành công!`,
      })
    );
    if (params && setParams) {
      setParams((pre) => {
        return { ...pre, render: !pre.render };
      });
    }
  };
  // const handleUpdateToken = async () => {
  //   setLoading(true);
  //   await refreshLead({});
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  //   dispatch(
  //     setToast({
  //       ...listToast[0],
  //       detail: `Cập nhật token thành công!`,
  //     })
  //   );
  //   if (params && setParams) {
  //     setParams((pre) => {
  //       return { ...pre, render: !pre.render };
  //     });
  //   }
  // };
  return (
    <div>
      {/* <Button className="m-3" onClick={handleZalo}>
        Test Zalo
      </Button> */}
      <ConfirmDialog
        header="Hello"
        visible={showConfirm}
        onHide={() => setOnHide()}
        accept={() => updateActive(pageChosen?.id)}
        position="center"
      ></ConfirmDialog>
      {showDialog && (
        <FormUpdateDialog
          visible={showDialog}
          setVisible={setShowDialog}
          title="Phân chia dự án"
          checkId={Number(showDialog)}
          setParams={setParams}
          actions={{ update: updateProjectCampaign }}
          handleData={handleData}
        >
          <div className="grid grid-form" style={{ marginBottom: "20vh" }}>
            <div className="col-6">
              <InputForm label="Tên page" value={campaignName} readOnly />
            </div>
            <div className="col-6 ">
              <DropdownForm
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_projectForm}
                optionLabel={(option) =>
                  `${option.cb_title}  (${option.cb_code})`
                }
                optionValue="cb_id"
                label="Dự án"
              />
            </div>
          </div>
        </FormUpdateDialog>
      )}
      <div className="card mx-auto ">
        <HeaderListForm title="Quản lý Page " />
        <Button
          onClick={() => handleUpdate()}
          loading={loading}
          className="mb-3"
        >
          Cập nhật Page - Chiến dịch
        </Button>
        {/* <Button
          onClick={() => handleUpdateToken()}
          loading={loading}
          className="mb-3"
        >
          Cập nhật lại
        </Button> */}
        <Header
          setParams={setParams}
          setFilter={setFilter}
          filter={filter}
          params={params}
        />

        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={list_page}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
        >
          <Columnz header="Page" field="page_name" />
          <Columnz
            header="ID Dự án"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <span className="mr-4">
                  {getProject(e.category_id)
                    ? getProject(e.category_id)?.cb_id
                    : "N/A"}
                </span>
              </div>
            )}
          />
          <Columnz
            header="Mã Dự án"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <span className="mr-4">
                  {getProject(e.category_id)
                    ? getProject(e.category_id)?.cb_code
                    : "N/A"}
                </span>
              </div>
            )}
          />
          <Columnz
            header="Dự án"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <span className="mr-4">
                  {getProject(e.category_id)
                    ? getProject(e.category_id)?.cb_title
                    : "Chưa có dự án"}
                </span>
                <i
                  className="pi pi-file-edit"
                  style={{ fontSize: "1.4rem", color: "#14b8a6" }}
                  onClick={() => handleShow(e?.page_name, e.id, e.category_id)}
                ></i>
              </div>
            )}
          />
          <Columnz
            body={(e) => (
              <Link to={`/page_list/page_detail/${e?.page_id}`}>
                {" "}
                <Button
                  type="button"
                  icon="pi pi-eye"
                  rounded
                  outlined
                  className={"mr-1 mx-auto flex"}
                />
              </Link>
            )}
            header="Danh sách chiến dịch"
          />
          <Columnz
            header="Active"
            body={(e) =>
              StatusBody({
                e,
                isAllow: true,
                updateAction: updateActivePage,
                setParams: setParams,
                params: params,
              })
            }
          />
        </DataTablez>
      </div>
    </div>
  );
};
export default PageList;
