import { useState } from "react";
import {
  useCountListLead,
  useGetUserLogToken,
  useListAllPage,
  useListAllProject,
  useListDepartment,
  useListLead,
  useListProjectForm,
  useListSource,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez } from "@/components/data_table";
import {
  Calendarz,
  Dropdownz,
  GridForm,
  HeaderListForm,
  Inputz,
} from "@/components/data_table/FormList";
import { setToast } from "@/redux/features";
import { listToast } from "@/constants";
import { useDispatch } from "react-redux";
import { ConfirmDialog } from "primereact/confirmdialog";
import DivideDialog from "./DivideDialog";
import SelectDay from "./SelectDay";
import { expListLeads, importLead, importLeadZalo, phanboDuan } from "../api";
import ImportService, { ImportService2 } from "./ImportService";
import AddLead from "./AddLead";
import {
  DropdownForm,
  FormUpdateDialog,
} from "@/components/data_table/FormUpdate";
const Header = ({ setParams, setFilter, filter }) => {
  const list_projectForm = useListProjectForm();
  const list_department = useListDepartment({
    status: undefined,
  });
  const list_source = useListSource();
  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Inputz
        value={filter?.key_search}
        className="mt-2 col-5"
        placeholder="Nhập tên khách hàng"
        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
      ></Inputz>
      <Dropdownz
        value={filter?.source}
        onChange={(e) => setFilter({ ...filter, source: e.target.value })}
        options={list_source}
        className="mt-2 col-4"
        placeholder="Chọn Nguồn"
        optionLabel="source"
        optionValue="source"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Dropdownz
        value={filter?.department_id}
        onChange={(e) =>
          setFilter({ ...filter, department_id: e.target.value })
        }
        options={list_department}
        className="mt-2 col-5"
        placeholder="Chọn Phòng ban"
        optionLabel={(option) =>
          `${option.gb_title}  (${option.reference_code})`
        }
        optionValue="gb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Dropdownz
        value={filter?.category_id}
        onChange={(e) => setFilter({ ...filter, category_id: e.target.value })}
        options={list_projectForm}
        className="mt-2 col-5"
        placeholder="Chọn dự án"
        optionLabel={(option) => `${option.cb_title}  (${option.cb_code})`}
        optionValue="cb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Calendarz
        className="mt-2 col-4"
        value={filter?.dates}
        onChange={(e) => setFilter({ ...filter, dates: e.value })}
        placeholder={"Chọn khoảng thời gian"}
        selectionMode="range"
      />
    </GridForm>
  );
};
const Divide = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [filter, setFilter] = useState();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const list_lead = useListLead({
    status: undefined,
    ...params,
    first: undefined,
    campaign_id: filter?.campaign_id,
    token: token,
  });
  const list_projectForm = useListProjectForm();
  const list_project = useListAllProject();
  const list_department = useListDepartment({
    status: undefined,
  });
  const list_page_form = useListAllPage({});
  const ParseDepartment = (id) => {
    const result = list_department
      ?.filter((e) => e.gb_id == id)
      .map((e) => e.gb_title)[0];
    return <span>{result ? result : "CHƯA CÓ PHÒNG BAN"}</span>;
  };
  const ParseCampaign = (campaign_id) => {
    const result = list_page_form
      ?.filter((e) => e.id == campaign_id)
      .map((e) => e.page_name)[0];
    return <span>{result}</span>;
  };
  const ParseCategory = (category_id) => {
    const category = list_projectForm?.find((e) => e.cb_id == category_id);
    if (category?.cb_title) return category?.cb_title;
    else return "Không có dự án";
  };
  const checkEqual = (arr) => {
    return new Set(arr).size === 1;
  };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const checkSelected = () => {
    if (!(selectedProducts && selectedProducts[0])) {
      dispatch(
        setToast({
          ...listToast[1],
          detail: "Vui lòng chọn khách hàng trước !",
        })
      );
      return;
    }
    if (selectedProducts && selectedProducts[0]) {
      if (!checkEqual(selectedProducts?.map((e) => e.category_id))) {
        dispatch(
          setToast({
            ...listToast[1],
            detail:
              "Khách hàng khác dự án không thể cùng phân bổ cho 1 phòng ban",
          })
        );
        return;
      }
    }
    setVisible(true);
  };

  const [visible, setVisible] = useState(false);
  const [leadVisible, setLeadVisible] = useState(false);
  const [dayVisible, setDayVisible] = useState(false);
  const [infos, setInfos] = useState();
  const [categoryVisible, setCategoryVisible] = useState(false);
  const items = [
    {
      label: "Phân bổ",
      command: () => checkSelected(),
    },
    {
      label: "Thêm khách mới",
      command: () => setLeadVisible(true),
    },
  ];
  const totalRecords =
    useCountListLead({
      status: undefined,
      ...params,
      first: undefined,
      project_id_ad: filter?.project_id_ad,
    }) || 0;
  const formatDate = (value) => {
    const data = value.split(" ")?.[0];
    const date_s = data.split("-");
    return `${value.split(" ")?.[1]} ${
      date_s?.[2] + "-" + date_s?.[1] + "-" + date_s?.[0]
    }`;
  };
  const handleData = () => {
    let info = { ...infos };
    return info;
  };
  const handleShow = (id, category_id) => {
    setInfos({ ...infos, category_id: category_id, id: id });
    setCategoryVisible(true);
  };
  async function handleUpdateData() {
    setDayVisible(true);
  }
  const [visibleImport, setVisibleImport] = useState(false);
  const [visibleImportZalo, setVisibleImportZalo] = useState(false);
  const list_source = useListSource();
  return (
    <div>
      {/* <Button onClick={() => handleTest()}>hello1</Button>
      <Button onClick={() => handleTest2()}>hello2</Button> */}
      <div className="card mx-auto ">
        <HeaderListForm title="Quản lý khách hàng" />
        <Header setParams={setParams} setFilter={setFilter} filter={filter} />
        <ConfirmDialog />
        {categoryVisible && (
          <FormUpdateDialog
            title="Phân Bổ Dự án"
            visible={categoryVisible}
            setVisible={setCategoryVisible}
            refreshObjects={[setInfos]}
            handleData={handleData}
            actions={{ update: phanboDuan }}
            checkId={Number(categoryVisible)}
            setParams={setParams}
          >
            <div>
              <DropdownForm
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_project}
                optionLabel="cb_title"
                optionValue="cb_id"
                label="Dự án"
              />
            </div>
            <div style={{ height: "25vh" }}></div>
          </FormUpdateDialog>
        )}
        {visible && (
          <DivideDialog
            visible={visible}
            setVisible={setVisible}
            selectedProducts={selectedProducts}
            setParams={setParams}
          />
        )}
        {leadVisible && (
          <AddLead
            leadVisible={leadVisible}
            setLeadVisible={setLeadVisible}
            setParams={setParams}
            list_source={list_source}
          />
        )}
        {visibleImport && (
          <ImportService
            visible={visibleImport}
            setVisible={setVisibleImport}
            setParams={setParams}
            action={importLead}
            title={"khách hàng"}
            type="khachhang"
          />
        )}
        {visibleImportZalo && (
          <ImportService2
            visible={visibleImportZalo}
            setVisible={setVisibleImportZalo}
            setParams={setParams}
            action={importLeadZalo}
            title={"khách hàng ( ZALO Form)"}
            type="khachhang"
          />
        )}
        {dayVisible && (
          <SelectDay
            visible={dayVisible}
            setVisible={setDayVisible}
            setParams={setParams}
            filter={filter}
          />
        )}
        <DataTablez
          title="thông tin khách hàng"
          value={list_lead}
          totalRecords={totalRecords}
          params={params}
          setVisibledDialog={setVisible}
          setParams={setParams}
          basePermissions={["export", "import", "zalo"]}
          headerInfo={{
            items,
            exportApi: () => expListLeads(params),
            setVisibleImport: setVisibleImport,
            setVisibleImportZalo: setVisibleImportZalo,
          }}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          handleUpdateData={handleUpdateData}
        >
          <Columnz
            field="name"
            header="Họ tên"
            bodyStyle={{ maxWidth: "150px" }}
          />
          <Columnz field="email" header="Email" />
          <Columnz field="phone" header="Số điện thoại" />
          <Columnz field="source" header="Nguồn" />
          <Columnz
            body={(e) => ParseDepartment(e.department_id)}
            header="Phòng ban"
          />
          <Columnz body={(e) => ParseCampaign(e.campaign_id)} header="Page" />
          <Columnz
            header="Dự án"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <span className="mr-4">{ParseCategory(e.category_id)}</span>
                {(e.category_id === null || e.category_id <= 0) && (
                  <i
                    className="pi pi-file-edit"
                    style={{
                      fontSize: "1.4rem",
                      color: "#14b8a6",
                      cursor: "pointer",
                    }}
                    onClick={() => handleShow(e.id, e.category_id)}
                  ></i>
                )}
              </div>
            )}
          />
          <Columnz
            body={(e) => formatDate(String(e.created_at))}
            bodyStyle={{ minWidth: "200px" }}
            header="Thời gian tạo"
          />
          <Columnz
            body={(e) => formatDate(String(e.create_date))}
            bodyStyle={{ minWidth: "200px" }}
            header="Thời gian thực"
          />
        </DataTablez>
      </div>
    </div>
  );
};
export default Divide;
